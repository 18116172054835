<template>
  <div id="client-view" class="page-layout__inner--column">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <section v-if="item.id" class="section">
          <div class="section-header">

          </div>
          <div class="section__body row pl-2">
            <div class="col-md-6 col-sm-12">

              <p class="section__title mb-3">{{ item.fullName }}</p>

              <div class="row">

                <div class="col-md-4 col-sm-12">
                  <div class="c-avatar-lg">
                    <img :src="$config.noProfilePhotoUrl" alt="фото" class="c-avatar-img">
                  </div>
                </div>

                <div class="col-md-8 col-sm-12">
                  <span class="text font-weight-bold"> {{ item.pin }} </span>
                  <span class="d-block text"> {{ item.bdate }} ({{ getTranslation('yearsOld', item.age) }}) </span>
                  <p class="text">{{ getTranslation(item.gender_text) }}</p>
                </div>

              </div>

            </div>
            <div class="col-md-6 col-sm-12">
              <p class="section__title mb-3">{{ getTranslation('contacts') }}</p>

              <div class="row">

                <div class="col-md-12 col-sm-12">
                  <table v-if="contact.id" class="table-doc">
                    <tbody>
                    <tr>
                      <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('email') }}:</th>
                      <td class="table-doc__cell">{{ contact.email }}</td>
                    </tr>
                    <tr>
                      <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('mobile') }}:</th>
                      <td class="table-doc__cell">
                        <span v-if="contact.mobileCode"> {{ contact.mobileCode.code }} </span>
                        {{ contact.mobile }}
                      </td>
                    </tr>
                    <tr>
                      <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('workPhone') }}:</th>
                      <td class="table-doc__cell">
                        <span v-if="contact.workPhoneCode"> {{ contact.workPhoneCode.code }} </span>
                        {{ contact.work_phone }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>

              </div>

            </div>
          </div>
        </section>
      </div>
      <div class="col-md-6 col-sm-12">
        <section v-if="user.id" class="section">
          <div class="section__header">
            <h3 class="section__title"> {{ getTranslation('user') }} </h3>
          </div>
          <div class="section__body">
            <table class="table-doc">
              <tbody>
              <tr>
                <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('username') }}:</th>
                <td class="table-doc__cell">{{ user.name }}</td>
              </tr>
              <tr>
                <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('email') }}:</th>
                <td class="table-doc__cell">
                  {{ user.email }}
                </td>
              </tr>
              <tr v-if="user.level">
                <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('role') }}:</th>
                <td class="table-doc__cell">
                  {{ getTranslation(user.level.slug) }}
                </td>
              </tr>
              <tr>
                <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('status') }}:</th>
                <td class="table-doc__cell">
                  {{ getTranslation(user.status_text) }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-pills nav-tabs-boxed">
          <li v-if="hasPermission('client.profile.apps')" class="nav-item">
            <a class="nav-link" :class="{'active':tabs.apps}" @click="tabSelect('apps')" href="javascript:void(0)">{{ getTranslation('applications') }}</a>
          </li>
        </ul>
        <div class="tab-content">
          <div v-if="hasPermission('client.profile.apps')" class="tab-pane fade" :class="{'show active':tabs.apps}">
            <AppComponent :active="tabs.apps" :item-id="item.id"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppComponent from "./components/AppComponent";

export default {
  name: "ViewClient",
  components: { AppComponent },
  data() {
    return {
      item: { id: null },
      contact: { id: null },
      user: { id: null },
      tabs: {
        apps: false
      }
    }
  },
  created() {
    this.fetchItem().then(() => {

      this.setHeadings({
        toBack: {
          label: this.getTranslation('toBack', this.getTranslation('clients')),
          route: { name: 'client.getList' }
        },
        breadcrumbs: [
          {
            to: { name: 'client.getList' },
            text: this.getTranslation('clients')
          },
          {
            text: this.item.fullName,
          }
        ]
      });

      this.fetchUser(this.item.id);
      this.tabSelect('apps');

    });
  },
  methods: {
    fetchItem() {
      return this.axios.get(this.$urls.client.get.url.replace(':id', this.$route.params.id))
          .then(response => {
            this.item = response.data.data;

            if ( response.data.data.contact )
              this.contact = response.data.data.contact;
          }).catch(() => {
          });
    },
    fetchUser(clientId) {
      this.axios.get(this.$urls.client.user.url.replace(':id', clientId))
          .then(response => {
            this.user = response.data.data;
          }).catch(() => {
      });
    }
  }
}
</script>

<style scoped>

</style>